import {
	SpRowLabel,
	SpRowLabelSizes,
	SpRowLabelType,
	SpTableRowProps,
	SpText,
} from '@dev.smartpricing/sp-vue-components'
import {
	Invoice,
	InvoiceStatusType,
	Subscription,
	SubscriptionStatusType,
} from '../submodules/sharedTypes/common/Billing'
import { Accommodation } from '../types/Accommodation'
import { utilDate } from './utilDate'
import { TrackingMessages } from '../constants/trackingMessages'
import InvoiceInfoShow from '../components/billing/InvoiceInfoShow.vue'
import { TranslationKeys } from '~/i18n/TranslationKeys'

export type RowLabelDefinition = {
	type?: SpRowLabelType
	size?: SpRowLabelSizes
	text: string
	class?: string
}

export type SubscriptionDefinition = {
	id: string
	toShow: boolean
	accommodations: Pick<Accommodation, 'name'>[]

	planLabel?: RowLabelDefinition
	statusLabel?: RowLabelDefinition

	dateLabel?: string
	dateLabelColor?: string
	date?: string

	amountLabel?: string
	amountLabelColor?: string
	amount?: string

	outsideText?: string
	outsideTextColor?: string

	hasScheduledChanges?: boolean
	scheduledChangesText?: string

	invoices?: InvoiceDefinition[]
}

export type InvoiceDefinition = {
	id: string
	toShow: boolean
	invoiceUrl?: string

	planLabel?: RowLabelDefinition
	statusLabel?: RowLabelDefinition

	customStyle?: {
		backgroundColor: string
		borderColor: string
	}

	date?: string

	amount?: string

	isExpandable?: boolean
	accommodations?: Pick<Accommodation, 'id' | 'name'>[]
}

export type InvoiceFilters = {
	year?: number
	subscriptionId?: string
	accommodationId?: number
	status?: InvoiceStatusType
	plan?: string // still don't know what this is
}

class UtilBilling {
	// subscriptions parser
	subscriptionParser(subscription: Subscription, subscriptionInvoices: Invoice[]): SubscriptionDefinition {
		const id = subscription.id
		const status = subscription.status
		const subscriptionPlanChip = this.planLabelCalc(subscription, SpRowLabelSizes.Big)

		const nextBillingDateFormatted = subscription.nextBillingDate
			? utilDate.formatLocale(subscription.nextBillingDate, useLocale().currentLocale.value)
			: '--'
		const nextBillingAmountFormatted = `${utilNumber.toCurrencyWithCents(subscription.nextBillingAmount, {
			code: subscription.currencyCode,
		})} +${useLocale().translate(TranslationKeys.VAT_LABEL)}`

		const hasScheduledChanges = subscription.hasScheduledChanges
		const changesOnNextRenewal =
			utilDate.formatDate(subscription.scheduledChange?.changeOnDate) ===
			utilDate.formatDate(subscription.nextBillingDate)
		const changesToPrice = subscription.scheduledChange?.newBillingAmount !== 0

		const hasUnpaidInvoices = subscription.hasUnpaidInvoices
		const totalUnpaidFormatted = utilNumber.toCurrencyWithCents(subscription.totalUnpaidAmount, {
			code: subscription.currencyCode,
		})
		const unpaidDateFormatted = subscription.unpaidSinceDate
			? utilDate.formatLocale(subscription.unpaidSinceDate, useLocale().currentLocale.value)
			: ''

		let unpaidInvoicesOutsideTextFormatted = ''
		if (subscription.totalUnpaidInvoices! > 1) {
			unpaidInvoicesOutsideTextFormatted = useLocale().translate(
				TranslationKeys.MULTIPLE_UNPAID_INVOICES,
				[String(subscription?.totalUnpaidInvoices), totalUnpaidFormatted],
				false
			)
		} else {
			unpaidInvoicesOutsideTextFormatted = useLocale().translate(
				TranslationKeys.UNPAID_INVOICE,
				[unpaidDateFormatted, totalUnpaidFormatted],
				false
			)
		}

		const textColor = hasUnpaidInvoices ? `text-red-500` : 'text-secondary-gray-900'

		const invoicePlanChip = this.planLabelCalc(subscription, SpRowLabelSizes.Small)
		const invoices = subscriptionInvoices?.map((invoice) => this.invoiceParser(invoice, invoicePlanChip)) || []

		switch (status) {
			default: {
				// valid for cancelled and transfered
				return {
					id: id,
					toShow: false,
					accommodations: [],
				}
			}

			case SubscriptionStatusType.InTrial: {
				return {
					id: id,
					toShow: true,
					planLabel: subscriptionPlanChip,
					statusLabel: {
						type: SpRowLabelType.Neutral,
						size: SpRowLabelSizes.Big,
						text: useLocale().translate(TranslationKeys.IN_TRIAL),
					},
					accommodations: subscription.accommodations,
					dateLabelColor: textColor,
					amountLabelColor: textColor,
					outsideTextColor: textColor,
					dateLabel: useLocale().translate(TranslationKeys.FIRST_PAYMENT),
					date: nextBillingDateFormatted,
					amountLabel: useLocale().translate(TranslationKeys.AMOUNT),
					amount: nextBillingAmountFormatted,
					outsideText: useLocale().translate(
						TranslationKeys.SUBSCRIPTION_TRIAL_OUTSIDE_TEXT,
						[nextBillingDateFormatted, nextBillingAmountFormatted],
						false
					),
					hasScheduledChanges: false,
					invoices: [
						{
							id: '',
							toShow: true,
							invoiceUrl: undefined,
							planLabel: invoicePlanChip,
							statusLabel: {
								type: SpRowLabelType.Neutral,
								size: SpRowLabelSizes.Small,
								text: useLocale().translate(TranslationKeys.INCOMING),
							},
							date: nextBillingDateFormatted,
							amount: nextBillingAmountFormatted,
							isExpandable: false,
							accommodations: undefined,
						},
					],
				}
			}

			case SubscriptionStatusType.Future: {
				return {
					id: id,
					toShow: true,
					planLabel: subscriptionPlanChip,
					statusLabel: {
						type: SpRowLabelType.Neutral,
						size: SpRowLabelSizes.Big,
						text: useLocale().translate(TranslationKeys.INCOMING),
					},
					accommodations: subscription.accommodations,
					dateLabel: useLocale().translate(TranslationKeys.FIRST_PAYMENT),
					date: nextBillingDateFormatted,
					dateLabelColor: textColor,
					amountLabelColor: textColor,
					outsideTextColor: textColor,
					amountLabel: useLocale().translate(TranslationKeys.AMOUNT),
					amount: nextBillingAmountFormatted,
					outsideText: useLocale().translate(
						TranslationKeys.SUBSCRIPTION_FUTURE_OUTSIDE_TEXT,
						[nextBillingDateFormatted, nextBillingAmountFormatted],
						false
					),
					hasScheduledChanges: false,
					invoices: [
						{
							id: '',
							toShow: true,
							invoiceUrl: undefined,
							planLabel: invoicePlanChip,
							statusLabel: {
								type: SpRowLabelType.Neutral,
								size: SpRowLabelSizes.Small,
								text: useLocale().translate(TranslationKeys.INCOMING),
							},
							date: nextBillingDateFormatted,
							amount: nextBillingAmountFormatted,
							isExpandable: false,
							accommodations: undefined,
						},
					],
				}
			}

			case SubscriptionStatusType.Paused: {
				const subStatus = this.subscriptionStatusLabelCalc(subscription, subscriptionInvoices, {
					type: SpRowLabelType.Neutral,
					size: SpRowLabelSizes.Big,
					text: useLocale().translate(TranslationKeys.PAUSED),
				})

				const pausedOnDate = subscription.pausedOnDate
					? utilDate.formatLocale(subscription.pausedOnDate, useLocale().currentLocale.value)
					: '--'

				return {
					id: id,
					toShow: true,
					planLabel: subscriptionPlanChip,
					statusLabel: subStatus,
					dateLabel: hasUnpaidInvoices
						? useLocale().translate(TranslationKeys.UNPAID_INVOICE_LABEL)
						: useLocale().translate(TranslationKeys.NEXT_PAYMENT_LABEL),
					accommodations: subscription.accommodations,
					dateLabelColor: textColor,
					amountLabelColor: textColor,
					outsideTextColor: textColor,
					date: hasUnpaidInvoices ? unpaidDateFormatted : pausedOnDate,
					amountLabel: hasUnpaidInvoices
						? useLocale().translate(TranslationKeys.AMOUNT_DUE)
						: useLocale().translate(TranslationKeys.AMOUNT),
					amount: hasUnpaidInvoices ? totalUnpaidFormatted : '--',
					outsideText: hasUnpaidInvoices
						? unpaidInvoicesOutsideTextFormatted
						: useLocale().translate(TranslationKeys.PAUSED_ON, [pausedOnDate]),
					hasScheduledChanges: false,
					invoices: invoices,
				}
			}

			case SubscriptionStatusType.NonRenewing: {
				const subStatus = this.subscriptionStatusLabelCalc(subscription, subscriptionInvoices, {
					type: SpRowLabelType.Neutral,
					size: SpRowLabelSizes.Big,
					text: useLocale().translate(TranslationKeys.TERMINATING),
				})

				const cancelledAtDate = utilDate.formatLocale(subscription.cancelledAtDate!, useLocale().currentLocale.value)

				return {
					id: id,
					toShow: true,
					planLabel: subscriptionPlanChip,
					statusLabel: subStatus,
					accommodations: subscription.accommodations,
					dateLabelColor: textColor,
					amountLabelColor: textColor,
					outsideTextColor: textColor,
					dateLabel: hasUnpaidInvoices
						? useLocale().translate(TranslationKeys.UNPAID_INVOICE_LABEL)
						: useLocale().translate(TranslationKeys.TERMINATING),
					date: hasUnpaidInvoices ? unpaidDateFormatted : cancelledAtDate,
					amountLabel: hasUnpaidInvoices
						? useLocale().translate(TranslationKeys.AMOUNT_DUE)
						: useLocale().translate(TranslationKeys.AMOUNT),
					amount: hasUnpaidInvoices ? totalUnpaidFormatted : '--',
					outsideText: hasUnpaidInvoices
						? unpaidInvoicesOutsideTextFormatted
						: useLocale().translate(TranslationKeys.TERMINATING_ON, [cancelledAtDate], false),
					hasScheduledChanges: hasScheduledChanges,
					invoices: invoices,
				}
			}

			case SubscriptionStatusType.Active: {
				if (hasScheduledChanges && changesOnNextRenewal && changesToPrice) {
					// subscription price change on next renewal
					const subStatus = this.subscriptionStatusLabelCalc(subscription, subscriptionInvoices, {
						type: SpRowLabelType.Positive,
						size: SpRowLabelSizes.Big,
						text: useLocale().translate(TranslationKeys.PAID),
					})

					const newPriceFormatted = `${utilNumber.toCurrencyWithCents(subscription.scheduledChange?.newBillingAmount, {
						code: subscription.currencyCode,
					})} +${useLocale().translate(TranslationKeys.VAT_LABEL)}`

					const outsideTextFormatted = useLocale().translate(
						TranslationKeys.NEXT_PAYMENT,
						[nextBillingDateFormatted, newPriceFormatted],
						false
					)

					return {
						id: id,
						toShow: true,
						planLabel: subscriptionPlanChip,
						statusLabel: subStatus,
						dateLabelColor: textColor,
						amountLabelColor: textColor,
						outsideTextColor: textColor,
						accommodations: subscription.accommodations,
						dateLabel: hasUnpaidInvoices
							? useLocale().translate(TranslationKeys.UNPAID_INVOICE_LABEL)
							: useLocale().translate(TranslationKeys.NEXT_PAYMENT_LABEL),
						date: hasUnpaidInvoices ? unpaidDateFormatted : nextBillingDateFormatted,
						amountLabel: hasUnpaidInvoices
							? useLocale().translate(TranslationKeys.AMOUNT_DUE)
							: useLocale().translate(TranslationKeys.AMOUNT),
						amount: hasUnpaidInvoices ? totalUnpaidFormatted : newPriceFormatted,
						outsideText: hasUnpaidInvoices ? unpaidInvoicesOutsideTextFormatted : outsideTextFormatted,
						hasScheduledChanges: false,
						invoices: [
							{
								id: '',
								toShow: true,
								invoiceUrl: undefined,
								planLabel: invoicePlanChip,
								statusLabel: {
									type: SpRowLabelType.Neutral,
									size: SpRowLabelSizes.Small,
									text: useLocale().translate(TranslationKeys.INCOMING),
								},
								date: nextBillingDateFormatted,
								amount: newPriceFormatted,
								isExpandable: false,
								accommodations: undefined,
							},
							...invoices,
						],
					}
				} else if (hasScheduledChanges && !changesOnNextRenewal && changesToPrice) {
					// subscription price change on another date
					const subStatus = this.subscriptionStatusLabelCalc(subscription, subscriptionInvoices, {
						type: SpRowLabelType.Positive,
						size: SpRowLabelSizes.Big,
						text: useLocale().translate(TranslationKeys.PAID),
					})

					const scheduledChangesText = useLocale().translate(TranslationKeys.SUBSCRIPTION_PRICE_CHANGE_TEMPLATE, [
						utilDate.formatLocale(subscription.scheduledChange?.changeOnDate!, useLocale().currentLocale.value),
						utilNumber.toCurrencyWithCents(subscription.scheduledChange?.newBillingAmount, {
							code: subscription.currencyCode,
						}),
					])

					return {
						id: id,
						toShow: true,
						planLabel: subscriptionPlanChip,
						statusLabel: subStatus,
						dateLabelColor: textColor,
						amountLabelColor: textColor,
						outsideTextColor: textColor,
						accommodations: subscription.accommodations,
						dateLabel: hasUnpaidInvoices
							? useLocale().translate(TranslationKeys.UNPAID_INVOICE_LABEL)
							: useLocale().translate(TranslationKeys.NEXT_PAYMENT_LABEL),
						date: hasUnpaidInvoices ? unpaidDateFormatted : nextBillingDateFormatted,
						amountLabel: hasUnpaidInvoices
							? useLocale().translate(TranslationKeys.AMOUNT_DUE)
							: useLocale().translate(TranslationKeys.AMOUNT),
						amount: hasUnpaidInvoices ? totalUnpaidFormatted : '--',
						outsideText: hasUnpaidInvoices
							? unpaidInvoicesOutsideTextFormatted
							: useLocale().translate(
									TranslationKeys.NEXT_PAYMENT,
									[nextBillingDateFormatted, nextBillingAmountFormatted],
									false
								),
						hasScheduledChanges: hasScheduledChanges,
						scheduledChangesText: scheduledChangesText,
						invoices: [
							{
								id: '',
								toShow: true,
								invoiceUrl: undefined,
								planLabel: invoicePlanChip,
								statusLabel: {
									type: SpRowLabelType.Neutral,
									size: SpRowLabelSizes.Small,
									text: useLocale().translate(TranslationKeys.INCOMING),
								},
								date: nextBillingDateFormatted,
								amount: nextBillingAmountFormatted,
								isExpandable: false,
								accommodations: undefined,
							},
							...invoices,
						],
					}
				} else if (hasScheduledChanges && changesOnNextRenewal && !changesToPrice) {
					// subscription termination on next renewal
					const subStatus = this.subscriptionStatusLabelCalc(subscription, subscriptionInvoices, {
						type: SpRowLabelType.Neutral,
						size: SpRowLabelSizes.Big,
						text: useLocale().translate(TranslationKeys.TERMINATING),
					})

					return {
						id: id,
						toShow: true,
						planLabel: subscriptionPlanChip,
						statusLabel: subStatus,
						accommodations: subscription.accommodations,
						dateLabel: hasUnpaidInvoices
							? useLocale().translate(TranslationKeys.UNPAID_INVOICE_LABEL)
							: useLocale().translate(TranslationKeys.TERMINATING),
						dateLabelColor: textColor,
						amountLabelColor: textColor,
						outsideTextColor: textColor,
						date: hasUnpaidInvoices ? unpaidDateFormatted : nextBillingDateFormatted,
						amountLabel: hasUnpaidInvoices
							? useLocale().translate(TranslationKeys.AMOUNT_DUE)
							: useLocale().translate(TranslationKeys.AMOUNT),
						amount: hasUnpaidInvoices ? totalUnpaidFormatted : '--',
						outsideText: hasUnpaidInvoices
							? unpaidInvoicesOutsideTextFormatted
							: useLocale().translate(TranslationKeys.TERMINATING_ON, [nextBillingDateFormatted], false),
						hasScheduledChanges: false,
						invoices: invoices,
					}
				} /* else if (hasScheduledChanges && !changesOnNextRenewal && !changesToPrice ) { // subscription termination on another date


                } */ else {
					// general case
					const subStatus = this.subscriptionStatusLabelCalc(subscription, subscriptionInvoices, {
						type: SpRowLabelType.Positive,
						size: SpRowLabelSizes.Big,
						text: useLocale().translate(TranslationKeys.PAID),
					})

					return {
						id: id,
						toShow: true,
						planLabel: subscriptionPlanChip,
						statusLabel: subStatus,
						accommodations: subscription.accommodations,
						dateLabel: hasUnpaidInvoices
							? useLocale().translate(TranslationKeys.UNPAID_INVOICE_LABEL)
							: useLocale().translate(TranslationKeys.NEXT_PAYMENT_LABEL),
						date: hasUnpaidInvoices ? unpaidDateFormatted : nextBillingDateFormatted,
						amountLabel: hasUnpaidInvoices
							? useLocale().translate(TranslationKeys.AMOUNT_DUE)
							: useLocale().translate(TranslationKeys.AMOUNT),
						dateLabelColor: textColor,
						amountLabelColor: textColor,
						outsideTextColor: textColor,
						amount: hasUnpaidInvoices ? totalUnpaidFormatted : nextBillingAmountFormatted,
						outsideText: hasUnpaidInvoices
							? unpaidInvoicesOutsideTextFormatted
							: useLocale().translate(
									TranslationKeys.NEXT_PAYMENT,
									[nextBillingDateFormatted, nextBillingAmountFormatted],
									false
								),
						hasScheduledChanges: hasScheduledChanges,
						invoices: [
							{
								id: '',
								toShow: true,
								invoiceUrl: undefined,
								planLabel: invoicePlanChip,
								statusLabel: {
									type: SpRowLabelType.Neutral,
									size: SpRowLabelSizes.Small,
									text: useLocale().translate(TranslationKeys.INCOMING),
								},
								date: nextBillingDateFormatted,
								amount: nextBillingAmountFormatted,
								isExpandable: false,
								accommodations: undefined,
							},
							...invoices,
						],
					}
				}
			}
		}
	}

	invoiceParser(invoice: Invoice, generalPlanLabel?: RowLabelDefinition, showAll: boolean = false): InvoiceDefinition {
		const planLabel = this.planLabelCalc(invoice, SpRowLabelSizes.Small)

		return {
			id: invoice.id,
			toShow:
				showAll ||
				[InvoiceStatusType.NotPaid, InvoiceStatusType.Pending, InvoiceStatusType.PaymentDue].includes(invoice.status),
			invoiceUrl: invoice.downloadUrl,
			customStyle: [InvoiceStatusType.NotPaid, InvoiceStatusType.PaymentDue].includes(invoice.status)
				? {
						backgroundColor: '#FEF2F2',
						borderColor: '#FF8C8C',
					}
				: undefined,
			planLabel: !!planLabel.text ? planLabel : generalPlanLabel,
			statusLabel: this.invoiceStatusLabelCalc(invoice, SpRowLabelSizes.Small),
			date: utilDate.formatLocale(invoice.date, useLocale().currentLocale.value),
			amount: utilNumber.toCurrencyWithCents(invoice.amount, { code: invoice.currencyCode || 'EUR' }),
			isExpandable: invoice.accommodations?.length > 1,
			accommodations: invoice.accommodations,
		}
	}

	// both subscriptions and invoices plan calculation
	planLabelCalc(item: Subscription | Invoice, size: SpRowLabelSizes = SpRowLabelSizes.Big): RowLabelDefinition {
		return {
			size: size,
			...(function (plan: string) {
				switch (plan) {
					case 'Smartpricing': {
						return {
							type: undefined,
							class: 'bg-main-blue-600 text-white',
							text: plan,
						}
					}
					case 'Smartpaying': {
						return {
							type: undefined,
							class: 'bg-light-blue-300 text-light-blue-900',
							text: plan,
						}
					}
					case 'Market': {
						return {
							type: undefined,
							class: 'bg-main-blue-200 text-main-blue-600',
							text: plan,
						}
					}
					case 'One Time': {
						return {
							type: undefined,
							class: 'bg-yellow-200 text-yellow-600',
							text: useLocale().translate(TranslationKeys.ONE_TIME_INVOICE_LABEL),
						}
					}
					default: {
						return {
							type: SpRowLabelType.Default,
							class: undefined,
							text: plan || '',
						}
					}
				}
			})(item?.plan || ''),
		}
	}

	// general subscription status
	subscriptionStatusLabelCalc(
		subscription: Subscription,
		subscriptionInvoices: Invoice[],
		generalLabel: RowLabelDefinition,
		size: SpRowLabelSizes = SpRowLabelSizes.Big
	): RowLabelDefinition {
		const hasPendingInvoices = subscriptionInvoices?.some((invoice) => {
			return [InvoiceStatusType.Posted, InvoiceStatusType.Pending].includes(invoice.status)
		})

		if (subscription.hasUnpaidInvoices) {
			return {
				type: SpRowLabelType.Negative,
				size: size,
				text: useLocale().translate(TranslationKeys.UNPAID),
			}
		} else if (hasPendingInvoices) {
			return {
				type: SpRowLabelType.SemiPositive,
				size: size,
				text: useLocale().translate(TranslationKeys.PENDING),
			}
		} else {
			return generalLabel
		}
	}

	// invoices status calculation
	invoiceStatusLabelCalc(invoice: Invoice, size: SpRowLabelSizes = SpRowLabelSizes.Small): RowLabelDefinition {
		const textByInvoiceStatus: { [key in InvoiceStatusType]: string } = {
			[InvoiceStatusType.Paid]: useLocale().translate(TranslationKeys.PAID),
			[InvoiceStatusType.Pending]: useLocale().translate(TranslationKeys.PENDING),
			[InvoiceStatusType.Posted]: useLocale().translate(TranslationKeys.PENDING),
			[InvoiceStatusType.NotPaid]: useLocale().translate(TranslationKeys.UNPAID),
			[InvoiceStatusType.PaymentDue]: useLocale().translate(TranslationKeys.UNPAID),
			[InvoiceStatusType.Voided]: '',
			[InvoiceStatusType.Unknown]: '',
		}

		const typeByInvoiceStatus: { [key in InvoiceStatusType]: SpRowLabelType } = {
			[InvoiceStatusType.Paid]: SpRowLabelType.Positive,
			[InvoiceStatusType.Pending]: SpRowLabelType.SemiPositive,
			[InvoiceStatusType.Posted]: SpRowLabelType.SemiPositive,
			[InvoiceStatusType.NotPaid]: SpRowLabelType.Negative,
			[InvoiceStatusType.PaymentDue]: SpRowLabelType.Negative,
			[InvoiceStatusType.Voided]: SpRowLabelType.Neutral,
			[InvoiceStatusType.Unknown]: SpRowLabelType.Neutral,
		}

		return {
			text: textByInvoiceStatus[invoice.status],
			type: typeByInvoiceStatus[invoice.status],
			size: size,
		}
	}

	// invoice filtering
	filterInvoices(invoices: Invoice[], filters: InvoiceFilters) {
		// for now only year
		return invoices.filter((invoice) => {
			return invoice.date.getFullYear() === filters.year
		})
	}

	// table formatting
	formatForTable(invoices: InvoiceDefinition[]): SpTableRowProps[] {
		const rows: SpTableRowProps[] = []

		invoices.forEach((invoice) => {
			if (invoice.toShow) {
				rows.push({
					isCheckable: false,
					isRemovable: false,
					isExpandable: !!invoice.isExpandable,
					customStyle: invoice.customStyle,
					cells: [
						{ component: SpText, props: { text: invoice.date } },
						{ component: SpText, props: { text: invoice.amount } },
						{ component: SpRowLabel, props: invoice.planLabel },
						{ component: SpRowLabel, props: invoice.statusLabel },
					],
					expandedCell: { component: InvoiceInfoShow, props: { invoice: invoice } },
					actions: [
						{
							id: 'download',
							iconName: SpCommonIcon.Download,
							show: !!invoice?.invoiceUrl && !!invoice?.invoiceUrl?.startsWith('http'),
							onClick(_: any) {
								const anchor = document.createElement('a')
								anchor.href = invoice?.invoiceUrl || ''
								anchor.target = '_blank'
								anchor.download = `invoice.pdf`
								anchor.click()
								anchor.remove()
								utilTracking.track(TrackingMessages.INVOICE_DOWNLOAD, {})
							},
						},
					],
				})
			}
		})

		return rows
	}
}

export const utilBilling = new UtilBilling()
