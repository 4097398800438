import { addDays, addYears, differenceInDays } from 'date-fns'
import { cloneDeep } from 'lodash'
import { defineStore } from 'pinia'
import { Core } from '../config/Core'
import { AnonymizationStructure } from '../config/SalesModesConfig'
import { FeedbackId } from '../constants/FeedbackId'
import { TrackingMessages } from '../constants/trackingMessages'
import {
	MarketIntelligenceAccommodation,
	MarketIntelligenceCompetitor,
	MarketIntelligenceCompetitorEdit,
	MarketIntelligenceCompetitorEditOperation,
	MarketIntelligenceCompetitorPrice,
	MarketIntelligenceMarketKind,
	MarketIntelligenceMarketPriceKind,
	MarketIntelligenceMarketPrices,
	MarketIntelligenceOccupancies,
} from '../submodules/sharedTypes/common/MarketIntelligence'
import { EditAccommodationMarketIntelligenceCompetitorNetworkObject } from '../submodules/sharedTypes/communication/accommodations/id/market-intelligence/EditAccommodationMarketIntelligenceCompetitorNetworkObject'
import { EditAccommodationMarketIntelligenceCompetitorPreviewNetworkObject } from '../submodules/sharedTypes/communication/accommodations/id/market-intelligence/EditAccommodationMarketIntelligenceCompetitorPreviewNetworkObject'
import { EditAccommodationMarketIntelligenceCompetitorPreviewResponse } from '../submodules/sharedTypes/communication/accommodations/id/market-intelligence/EditAccommodationMarketIntelligenceCompetitorPreviewResponse'
import { EditAccommodationMarketIntelligenceCompetitorPreviewStrategyNetworkObject } from '../submodules/sharedTypes/communication/accommodations/id/market-intelligence/EditAccommodationMarketIntelligenceCompetitorPreviewStrategyNetworkObject'
import { EditAccommodationMarketIntelligenceCompetitorPreviewStrategyResponse } from '../submodules/sharedTypes/communication/accommodations/id/market-intelligence/EditAccommodationMarketIntelligenceCompetitorPreviewStrategyResponse'
import { GetAccommodationMarketIntelligenceCompetitorsPricesNetworkObject } from '../submodules/sharedTypes/communication/accommodations/id/market-intelligence/GetAccommodationMarketIntelligenceCompetitorsPricesNetworkObject'
import { GetAccommodationMarketIntelligenceCompetitorsPricesRequest } from '../submodules/sharedTypes/communication/accommodations/id/market-intelligence/GetAccommodationMarketIntelligenceCompetitorsPricesRequest'
import { GetAccommodationMarketIntelligenceCompetitorsPricesResponse } from '../submodules/sharedTypes/communication/accommodations/id/market-intelligence/GetAccommodationMarketIntelligenceCompetitorsPricesResponse'
import { GetAccommodationMarketIntelligenceDataNetworkObject } from '../submodules/sharedTypes/communication/accommodations/id/market-intelligence/GetAccommodationMarketIntelligenceDataNetworkObject'
import { GetAccommodationMarketIntelligenceDataResponse } from '../submodules/sharedTypes/communication/accommodations/id/market-intelligence/GetAccommodationMarketIntelligenceDataResponse'
import { ResetAccommodationMarketIntelligenceCompetitorSetNetworkObject } from '../submodules/sharedTypes/communication/accommodations/id/market-intelligence/ResetAccommodationMarketIntelligenceCompetitorSetNetworkObject'
import { Accommodation } from '../types/Accommodation'
import { ChartType } from '../types/Chart'
import {
	MarketIntelligenceChartSeriesKind,
	MarketIntelligenceCompetitorForList,
	MarketIntelligenceCompetitorPriceForMap,
} from '../types/MarketIntelligence'
import { ChannelManagerName } from '@dev.smartpricing/sp-shared-network-freemium-types'

export const useMarketIntelligenceStore = defineStore('market-intelligence', () => {
	const accommodationsStore = useAccommodationsStore()
	const { accommodations } = storeToRefs(accommodationsStore)

	const strategiesStore = useStrategiesStore()

	const pricesStore = usePricesStore()
	const { getPriceByRoomTypeId } = pricesStore

	const accommodation = ref<MarketIntelligenceAccommodation | undefined>(undefined)
	const selectedAccommodation = ref<Accommodation>(
		accommodations.value.find((accommodation) => isMarketIntelligenceEnabledOnAccommodation(accommodation))!
	)
	const selectedRoomTypeId = ref<number>()

	const marketCompleteness = ref<number>()

	const competitors = ref<MarketIntelligenceCompetitorForList[]>([])
	const edits = ref<MarketIntelligenceCompetitorEdit[]>([])
	const isCompsetChanged = computed(() => edits.value.length > 0)
	const strategyMarketCompetitors = computed(() =>
		// A little trick. We need to show all the competitors discovered by the algorithm
		// as they was also in the strategy market. Later in the list we style them differently.
		competitors.value.filter((competitor) => competitor.isDiscoveredByAlgorithm || competitor.isInStrategyMarket)
	)

	const competitorsPrices = ref<Record<number, MarketIntelligenceCompetitorPriceForMap[]>>({})
	const competitorsHistoricalPrices = ref<Record<number, MarketIntelligenceCompetitorPriceForMap[]>>({})

	const marketPrices = ref<MarketIntelligenceMarketPrices | undefined>(undefined)
	const marketOccupancies = ref<MarketIntelligenceOccupancies | undefined>(undefined)

	const isLoadingMarketIntelligenceData = ref(false)
	const isLoadingMarketIntelligenceCompetitorsPrices = ref(false)

	const isCompetitorPricesFetched = computed(() => (competitorId: number) => {
		if (competitorsPrices.value[competitorId]) {
			return true
		}

		return false
	})

	const shouldFetchCompetitorPrices = computed(() => (competitorId: number) => {
		if (!competitorsPrices.value[competitorId]) {
			return true
		}

		return false
	})

	const activeChartSeries = ref<Record<string, boolean>>({
		[MarketIntelligenceChartSeriesKind.CurrentAccommodation]: true,
		[MarketIntelligenceChartSeriesKind.SmarpricingStrategy]: true,
		[MarketIntelligenceChartSeriesKind.MinMaxmarketTrend]: true,
		[MarketIntelligenceChartSeriesKind.MinMaxOccupancyTrend]: true,
		[MarketIntelligenceChartSeriesKind.CurrentAccommodationOccupancy]: true,
	})
	const selectedMarketKind = ref<number>(0)
	const marketPricesChartData = computed(() => [
		{
			type: ChartType.Line.toLowerCase(),
			id: MarketIntelligenceChartSeriesKind.CurrentAccommodation,
			data: accommodation.value?.useBookingPrices
				? accommodation.value.bookingPrices?.map((price) => [price.date, price.price])
				: selectedAccommodationPrices.value.map((price) => [price.date, price.publishedPrice]),
			name: selectedAccommodation.value?.name ?? useLocale().translate(TranslationKeys.YOUR_ACCOMMODATION),
			color: Core.colors.charts.line.accommodation,
			showSymbol: false,
		},
		{
			id: MarketIntelligenceChartSeriesKind.MinMaxmarketTrend,
			name: useLocale().translate(TranslationKeys.MIN_MAX_MARKET),
			data: marketPrices.value?.[
				selectedMarketKind.value === 1
					? MarketIntelligenceMarketKind.Strategy
					: MarketIntelligenceMarketKind.MarketIntelligence
			][MarketIntelligenceMarketPriceKind.MinMaxMarketPrice].map((price) => [price.date, price.marketLowerPrice]),
			type: 'line',
			color: Core.colors.charts.line.marketThreshold,
			lineStyle: {
				color: 'transparent',
				width: 0,
			},
			showSymbol: false,
			stack: `${MarketIntelligenceChartSeriesKind.MinMaxmarketTrend}_stacked`,
		},
		{
			id: `${MarketIntelligenceChartSeriesKind.MinMaxmarketTrend}_stacked`,
			name: useLocale().translate(TranslationKeys.MIN_MAX_MARKET),
			data: marketPrices.value?.[
				selectedMarketKind.value === 1
					? MarketIntelligenceMarketKind.Strategy
					: MarketIntelligenceMarketKind.MarketIntelligence
			][MarketIntelligenceMarketPriceKind.MinMaxMarketPrice].map((price) => [
				price.date,
				price.marketUpperPrice - price.marketLowerPrice,
			]),
			type: 'line',
			color: Core.colors.charts.line.marketThreshold,
			areaStyle: {
				color: Core.colors.charts.line.marketThreshold.serie,
				opacity: 1,
			},
			lineStyle: {
				width: 0,
			},
			showSymbol: false,
			stack: `${MarketIntelligenceChartSeriesKind.MinMaxmarketTrend}_stacked`,
		},
		...competitors.value
			.filter((competitor) => competitor.isSelectedInCompetitorsList)
			.map((competitor, index) => {
				const competitorPrices = competitorsPrices.value[competitor.id]
				return {
					id: competitor.id,
					data: competitorPrices?.map((price) => [price.date, price.price]),
					type: 'line',
					name: competitor.name,
					color: getColorData()[index],
					showSymbol: false,
				}
			}),
		...competitors.value
			.filter((competitor) => competitor.isSelectedInCompetitorsList)
			.map((competitor, index) => {
				const competitorPrices = competitorsHistoricalPrices.value[competitor.id]
				return {
					id: `${competitor.id}_historical`,
					data: competitorPrices?.map((price) => [price.date, price.price, price.updatedAt]),
					type: 'line',
					name: competitor.name,
					color: getColorData()[index],
					showSymbol: false,
					lineStyle: {
						type: 'dotted',
					},
				}
			}),
	])

	const marketOccupancyChartData = computed(() => {
		const accommodationOccupancy = useOccupancyStore().accommodationOccupancyById(
			selectedAccommodation.value.id as unknown as number
		)

		const marketOccupancyData =
			selectedMarketKind.value === 1
				? marketOccupancies.value?.[MarketIntelligenceMarketKind.Strategy].occupancy
				: marketOccupancies.value?.[MarketIntelligenceMarketKind.MarketIntelligence].occupancy

		return [
			{
				type: ChartType.Line.toLowerCase(),
				id: MarketIntelligenceChartSeriesKind.CurrentAccommodationOccupancy,
				data: accommodationOccupancy.map((occupancy) => [occupancy.date, occupancy.occupancy * 100]),
				name: useLocale().translate(TranslationKeys['competitors.chart_your_occupancy.LABEL']),
				color: Core.colors.charts.line.currentAccommodationOccupancy,
				showSymbol: false,
			},
			{
				id: MarketIntelligenceChartSeriesKind.MinMaxOccupancyTrend,
				data: marketOccupancyData?.map((occupancy) => {
					if (occupancy.lower != null && occupancy.upper != null) {
						return [occupancy.date, occupancy.lower * 100]
					}

					return [occupancy.date, null]
				}),
				type: 'line',
				name: useLocale().translate(TranslationKeys['competitors.chart_market_occupancy.LABEL']),
				showSymbol: false,
				color: Core.colors.charts.line.minMaxOccupancyTrend,
				stack: `${MarketIntelligenceChartSeriesKind.MinMaxOccupancyTrend}_stacked`,
				lineStyle: {
					color: 'transparent',
					width: 0,
				},
			},
			{
				id: `${MarketIntelligenceChartSeriesKind.MinMaxOccupancyTrend}_stacked`,
				data: marketOccupancyData?.map((occupancy) => {
					if (occupancy.lower != null && occupancy.upper != null) {
						return [occupancy.date, occupancy.upper * 100 - occupancy.lower * 100]
					}

					return [occupancy.date, null]
				}),
				color: Core.colors.charts.line.minMaxOccupancyTrend,
				areaStyle: {
					color: Core.colors.charts.line.minMaxOccupancyTrend.serie,
					opacity: 1,
				},
				lineStyle: {
					width: 0,
				},
				type: 'line',
				name: useLocale().translate(TranslationKeys['competitors.chart_market_occupancy.LABEL']),
				showSymbol: false,
				stack: `${MarketIntelligenceChartSeriesKind.MinMaxOccupancyTrend}_stacked`,
			},
		]
	})

	const isPricesPreviewLoading = ref(false)
	async function loadPricesPreview(competitorId: string) {
		isPricesPreviewLoading.value = true

		await utilNetwork.simpleRequest(
			new EditAccommodationMarketIntelligenceCompetitorPreviewStrategyNetworkObject({
				id: competitorId,
				edits: edits.value,
			}),
			setMarketIntelligenceStrategyPreviewData
		)

		isPricesPreviewLoading.value = false
	}
	async function updateEdits(competitorId: number, operations: MarketIntelligenceCompetitorEditOperation[]) {
		edits.value.push(...operations.map((operation) => ({ hotel_id: competitorId, operation })))

		const competitor = competitors.value.find((competitor) => competitor.id === competitorId)

		if (competitor) {
			competitor.isLoading = true
		}

		const promises = [
			loadPricesPreview(selectedAccommodation.value!.id as string),
			utilNetwork.simpleRequest(
				new EditAccommodationMarketIntelligenceCompetitorPreviewNetworkObject({
					id: selectedAccommodation.value!.id as string,
					edits: edits.value,
				}),
				setMarketIntelligencePreviewData
			),
		]
		await Promise.all(promises)

		if (competitor) {
			competitor.isLoading = false
		}
	}

	function getMarketIntelligenceData() {
		setIsLoadingMarketIntelligenceData(true)

		return Promise.all([
			pricesStore.requestPrices({ from: new Date(), to: addYears(new Date(), 1) }),
			utilNetwork.simpleRequest(
				new GetAccommodationMarketIntelligenceDataNetworkObject({ id: selectedAccommodation.value!.id as string }),
				setMarketIntelligenceData
			),
		])
	}

	function updateMarketIntelligenceData() {
		utilNetwork.simpleRequest(
			new EditAccommodationMarketIntelligenceCompetitorNetworkObject({
				id: selectedAccommodation.value!.id as string,
				edits: edits.value,
			}),
			setMarketIntelligenceData
		)

		useFeedbackStore().requestFeedback(FeedbackId.StrategyCompsetSave)

		const groupedEdits = Object.values(Object.groupBy(edits.value, (edit) => edit.hotel_id.toString()))
		utilTracking.track(TrackingMessages.MARKET_SAVED, {
			restored_competitors_count: groupedEdits.filter(
				(editGroup) =>
					editGroup &&
					editGroup.length === 2 &&
					editGroup.map((group) => group.operation).includes(MarketIntelligenceCompetitorEditOperation.Unfreeze) &&
					editGroup.map((group) => group.operation).includes(MarketIntelligenceCompetitorEditOperation.AddStrategy)
			).length,
			added_competitors_count: groupedEdits.filter(
				(editGroup) =>
					editGroup && editGroup.map((group) => group.operation).includes(MarketIntelligenceCompetitorEditOperation.Add)
			).length,
			removed_competitors_count: groupedEdits.filter(
				(editGroup) =>
					editGroup &&
					editGroup.length <= 1 &&
					editGroup[0].operation === MarketIntelligenceCompetitorEditOperation.Remove
			).length,
			added_strategic_competitors_count: groupedEdits.filter(
				(editGroup) =>
					editGroup &&
					editGroup.map((group) => group.operation).includes(MarketIntelligenceCompetitorEditOperation.AddStrategy)
			).length,
			removed_strategic_competitors_count: groupedEdits.filter(
				(editGroup) =>
					editGroup &&
					editGroup.length <= 1 &&
					editGroup[0].operation === MarketIntelligenceCompetitorEditOperation.RemoveStrategy
			).length,
			locked_competitors_count: groupedEdits.filter(
				(editGroup) =>
					editGroup &&
					editGroup.length <= 1 &&
					editGroup[0].operation === MarketIntelligenceCompetitorEditOperation.Freeze
			).length,
			unlocked_competitors_count: groupedEdits.filter(
				(editGroup) =>
					editGroup &&
					editGroup.length <= 1 &&
					editGroup[0].operation === MarketIntelligenceCompetitorEditOperation.Unfreeze
			).length,
		})
	}

	function getMarketIntelligenceCompetitorPrices(params: GetAccommodationMarketIntelligenceCompetitorsPricesRequest) {
		setIsLoadingMarketIntelligenceCompetitorsPrices(true)

		return utilNetwork.simpleRequest(
			new GetAccommodationMarketIntelligenceCompetitorsPricesNetworkObject(params),
			setMarketIntelligenceCompetitorsPrices
		)
	}

	function setSelectedAccommodation(receivedAccommodation: MarketIntelligenceAccommodation) {
		accommodation.value = receivedAccommodation
	}

	const selectedAccommodationPrices = computed(() => {
		const selectedAccommodationRoomType = selectedAccommodation.value?.roomTypes?.find(
			(roomType) => roomType.id === selectedRoomTypeId.value
		)

		if (!selectedAccommodationRoomType) {
			return []
		}

		return getPriceByRoomTypeId(selectedAccommodationRoomType.id as number)
	})

	function setCompetitors(receivedCompetitors: MarketIntelligenceCompetitor[]) {
		competitors.value = receivedCompetitors.map((competitor) => ({
			...competitor,
			isLoading: false,
			isSelectedInCompetitorsList: false,
			isSelectedInChart: false,
		}))
	}

	function setMarketIntelligenceData(response: GetAccommodationMarketIntelligenceDataResponse) {
		edits.value = []

		if (response != undefined) {
			setSelectedAccommodation(response.accommodation)
			setCompetitors(response.competitors)
			setMarketPrices(response.market)
			marketCompleteness.value = response.marketCompleteness
			setMarketOccupancy(response.occupancies)
		}

		setIsLoadingMarketIntelligenceData(false)
	}

	function setMarketIntelligencePreviewData(response: EditAccommodationMarketIntelligenceCompetitorPreviewResponse) {
		setSelectedAccommodation(response.accommodation)
		setCompetitors(response.competitors)
		setMarketPrices(response.market)
		marketCompleteness.value = response.marketCompleteness
		setMarketOccupancy(response.occupancies)

		setIsLoadingMarketIntelligenceData(false)
	}

	function setMarketIntelligenceStrategyPreviewData(
		response: EditAccommodationMarketIntelligenceCompetitorPreviewStrategyResponse
	) {
		const daysLimitForPriceSeries = addDays(new Date(), 500)

		response.predictedPrices.forEach((roomTypePricesPrediction) => {
			const publishedPrices: StrategyChartLineDataPoint[] = []
			const currentPrices: StrategyChartLineDataPoint[] = []

			roomTypePricesPrediction.prices.forEach((pricePrediction) => {
				const pricePredictionDate = new Date(pricePrediction.date)
				pricePredictionDate.setHours(0, 0, 0, 0)

				if (differenceInDays(pricePredictionDate, daysLimitForPriceSeries) <= 0) {
					currentPrices.push({
						time: pricePredictionDate,
						value: pricePrediction.suggestedPrice,
					})
					publishedPrices.push({
						time: pricePredictionDate,
						value: pricePrediction.publishedPrice,
					})
				}
			})

			strategiesStore.pricePredictionsFormatted.current_preview[roomTypePricesPrediction.id] = currentPrices
			strategiesStore.pricePredictionsFormatted.published_preview[roomTypePricesPrediction.id] = publishedPrices
		})
	}

	function setCompetitorsPrices(receivedCompetitorsPrices: Record<number, MarketIntelligenceCompetitorPrice[]>) {
		Object.entries(receivedCompetitorsPrices).forEach(([competitorId, prices]) => {
			competitorsPrices.value[Number(competitorId)] = prices
		})
	}

	function setCompetitorsHistoricalPrices(
		receivedCompetitorsPrices: Record<number, MarketIntelligenceCompetitorPrice[]>
	) {
		Object.entries(receivedCompetitorsPrices).forEach(([competitorId, prices]) => {
			competitorsHistoricalPrices.value[Number(competitorId)] = prices
		})
	}

	function setMarketPrices(receivedMarketPrices: MarketIntelligenceMarketPrices) {
		marketPrices.value = receivedMarketPrices
	}

	function setMarketOccupancy(occupancies: MarketIntelligenceOccupancies) {
		marketOccupancies.value = occupancies
	}

	function setMarketIntelligenceCompetitorsPrices(
		response: GetAccommodationMarketIntelligenceCompetitorsPricesResponse
	) {
		setCompetitorsPrices(response.prices)
		setCompetitorsHistoricalPrices(response.prices_historical)

		setIsLoadingMarketIntelligenceCompetitorsPrices(false)
	}

	function setIsLoadingMarketIntelligenceData(isLoading: boolean) {
		isLoadingMarketIntelligenceData.value = isLoading
	}

	function setIsLoadingMarketIntelligenceCompetitorsPrices(isLoading: boolean) {
		isLoadingMarketIntelligenceCompetitorsPrices.value = isLoading
	}

	function setSelectedAccommodationById(accommodationId: string) {
		// @ts-ignore
		selectedAccommodation.value = accommodations.value.find((accommodation) => accommodation.id === accommodationId)
		edits.value = []
	}

	async function addCompetitor(
		competitor: MarketIntelligenceCompetitor,
		includdeInStrategyMarket: boolean = false,
		source: 'toolbar' | 'list' | 'replace' = 'list'
	) {
		useFeedbackStore().requestFeedback(FeedbackId.Add3OrMoreCompetitors)

		// add a placeholder to show the skeleton
		competitors.value.unshift({
			...competitor,
			isLoading: true,
			isSelectedInChart: false,
			isSelectedInCompetitorsList: false,
			isAddedByUser: true,
			isInStrategyMarket: includdeInStrategyMarket,
		})

		// Track action
		const trackingUISource: Record<typeof source, string> = {
			toolbar: 'Toolbar button',
			list: 'List button',
			replace: 'Replace button',
		}
		utilTracking.track(TrackingMessages.COMPETITOR_ADD, {
			competitor_name: competitor.name,
			competitor_url: competitor.channelManagerURLs['booking.com'],
			competitor_score: competitor.score,
			competitor_relevance: competitor.relevance,
			ui_source: trackingUISource[source],
		})

		const operations = [MarketIntelligenceCompetitorEditOperation.Add]
		if (includdeInStrategyMarket) {
			operations.push(MarketIntelligenceCompetitorEditOperation.AddStrategy)

			utilTracking.track(TrackingMessages.STRATEGY_COMPETITOR_ADDED, {
				comp_name: competitor?.name,
				comp_url: competitor?.channelManagerURLs[ChannelManagerName.Booking],
			})
		}

		await updateEdits(competitor.id, operations)
	}

	// Actions on competitor
	async function toggleCompetitorFromStrategyMarket(
		id: number,
		operation:
			| MarketIntelligenceCompetitorEditOperation.AddStrategy
			| MarketIntelligenceCompetitorEditOperation.RemoveStrategy
	) {
		const actualCompetitor = competitors.value.find((competitor) => competitor.id === id)
		if (operation === MarketIntelligenceCompetitorEditOperation.AddStrategy) {
			utilTracking.track(TrackingMessages.STRATEGY_COMPETITOR_ADDED, {
				comp_name: actualCompetitor?.name,
				comp_url: actualCompetitor?.channelManagerURLs[ChannelManagerName.Booking],
			})
		} else {
			utilTracking.track(TrackingMessages.STRATEGY_COMPETITOR_REMOVED, {
				comp_name: actualCompetitor?.name,
				comp_url: actualCompetitor?.channelManagerURLs[ChannelManagerName.Booking],
				is_suggested: actualCompetitor?.isDiscoveredByAlgorithm,
			})
		}

		await updateEdits(id, [operation])
	}

	async function removeCompetitor(id: number) {
		const actualCompetitor = competitors.value.find((competitor) => competitor.id === id)
		utilTracking.track(TrackingMessages.STRATEGY_COMPETITOR_DELETED, {
			comp_name: actualCompetitor?.name,
			comp_url: actualCompetitor?.channelManagerURLs[ChannelManagerName.Booking],
			is_suggested: actualCompetitor?.isDiscoveredByAlgorithm,
		})

		await updateEdits(id, [MarketIntelligenceCompetitorEditOperation.Remove])
	}

	async function toggleCompetitorFreeze(
		id: number,
		operation: MarketIntelligenceCompetitorEditOperation.Freeze | MarketIntelligenceCompetitorEditOperation.Unfreeze
	) {
		const actualCompetitor = competitors.value.find((competitor) => competitor.id === id)
		utilTracking.track(TrackingMessages.STRATEGY_COMPETITOR_LOCKED, {
			comp_name: actualCompetitor?.name,
			comp_url: actualCompetitor?.channelManagerURLs[ChannelManagerName.Booking],
		})

		await updateEdits(id, [operation])
	}

	async function restoreCompetitor(id: number) {
		const actualCompetitor = competitors.value.find((competitor) => competitor.id === id)
		utilTracking.track(TrackingMessages.STRATEGY_COMPETITOR_ADDED, {
			comp_name: actualCompetitor?.name,
			comp_url: actualCompetitor?.channelManagerURLs[ChannelManagerName.Booking],
		})

		await updateEdits(id, [
			MarketIntelligenceCompetitorEditOperation.Unfreeze,
			MarketIntelligenceCompetitorEditOperation.AddStrategy,
		])
	}

	async function resetCompetitorSet() {
		await utilNetwork.simpleRequest(
			new ResetAccommodationMarketIntelligenceCompetitorSetNetworkObject({
				id: selectedAccommodation.value!.id as string,
			}),
			() => {}
		)

		utilTracking.track(TrackingMessages.MARKET_RESTORED, {})

		await getMarketIntelligenceData()
	}

	function toggleCompetitorSelection(competitorId: number) {
		const competitor = competitors.value.find((competitor) => competitor.id === competitorId)
		const actualCompetitor = competitors.value.find((competitor) => competitor.id === competitorId)
		if (!competitor || !actualCompetitor) {
			return
		}

		actualCompetitor.isSelectedInCompetitorsList = !competitor.isSelectedInCompetitorsList
		activeChartSeries.value[competitorId] = actualCompetitor.isSelectedInCompetitorsList

		if (shouldFetchCompetitorPrices.value(competitorId)) {
			getMarketIntelligenceCompetitorPrices({
				id: selectedAccommodation.value!.id as string,
			})
		}
	}

	async function anonymizeDeanonymizeCurrentAccommodation(mode: 'anon' | 'deanon', config?: AnonymizationStructure) {
		if (!selectedAccommodation.value) {
			return
		}

		const label = config ? useLocale().translate(config.accommodation) : ''
		const accommodation: Accommodation & { demoMode?: boolean } = cloneDeep(toRaw(selectedAccommodation.value))
		if (mode === 'anon') {
			if (accommodation.demoMode) {
				accommodation.name = accommodation.name
			}

			accommodation.name = label
			accommodation.demoMode = true
		} else {
			accommodation.name = accommodation.name
			accommodation.demoMode = false
		}

		selectedAccommodation.value = accommodation
	}

	function undoChanges() {
		edits.value = []
	}

	return {
		accommodation,
		selectedAccommodation,
		selectedRoomTypeId,

		marketCompleteness,
		selectedAccommodationPrices,

		competitors,
		strategyMarketCompetitors,
		competitorsPrices,

		isCompetitorPricesFetched,
		shouldFetchCompetitorPrices,
		activeChartSeries,
		selectedMarketKind,
		marketPricesChartData,
		marketOccupancyChartData,
		marketPrices,
		marketOccupancies,

		isLoadingMarketIntelligenceData,
		isLoadingMarketIntelligenceCompetitorsPrices,
		isCompsetChanged,
		isPricesPreviewLoading,

		getMarketIntelligenceData,
		updateMarketIntelligenceData,
		getMarketIntelligenceCompetitorPrices,

		setSelectedAccommodationById,

		addCompetitor,
		toggleCompetitorFromStrategyMarket,
		removeCompetitor,
		toggleCompetitorFreeze,
		restoreCompetitor,
		resetCompetitorSet,
		toggleCompetitorSelection,
		undoChanges,

		anonymizeDeanonymizeCurrentAccommodation,
	}
})
